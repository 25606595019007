import { Box, Button, TextField } from '@mui/material'
import { ContainedButton } from 'src/components/Button'
import { DarkLogo } from 'src/components/SymanisLogo'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { __DEV__, regExEmail, regExPhone, testingEmail } from 'src/share/constants'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import parsePhoneNumber from 'libphonenumber-js'
import { loginWithEmailFn, loginWithPhoneFn } from 'src/api'
import AuthPage from './AuthPage'

export default function HomePage() {
  const [email, setEmail] = useState(__DEV__ ? testingEmail : '')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [open, setOpen] = useState(false)
  const [sessionId, setSessionId] = useState("")
  const [ttr, setTtr] = useState('')
  const userEmail = searchParams.get("userEmail");
  const sessionEmail = localStorage.getItem("last_user");
  const [showContinue, setShowContinue] = useState(false)
  function handleRestSecret() {
    navigate('/reset-secrets')
  }
  function handleRegister() {
    navigate('/register')
  }
  const { mutate: submitEmailLogin, isLoading: loadingEmail } = useMutation(loginWithEmailFn, {
    onSuccess: (data) => {
      const sessionId = data.sessionId
      const ttrCookie = data.ttrCookie
      setSessionId(sessionId)
      setTtr(ttrCookie)
      setOpen(true)
    },
    onError: (error: any) => {
      if (error.response.status === 405) {
        alert(
          "You need to finish registration process. Please register again."
        );
        return navigate("/register");
      }
      alert(error.response.data.message);
    },
  }
  )
  const { mutate: submitPhoneLogin, isLoading: loadingPhone } = useMutation(loginWithPhoneFn, {
    onSuccess: (data) => {
      const sessionId = String(data.sessionId)
      setSessionId(sessionId)
      setOpen(true)
    },
    onError: (error: any) => {
      if (error.response.status === 405) {
        alert(
          "You need to finish registration process. Please register again."
        );
        return navigate("/register");
      }
      alert(error.response.data.message);
    },
  })

  useEffect(() => {
    if (!userEmail && sessionEmail && sessionEmail.length > 0 && searchParams.get("SAMLRequest")) {
      setShowContinue(true)
    }
  }, [sessionEmail])

  useEffect(() => {
    if (performance.navigation.type === 1 && userEmail) {
      navigate({ pathname: "/" });
    } else if (userEmail) {
      const timeoutId = setTimeout(() => {
        setEmail(userEmail);
        if (regExEmail.test(userEmail)) {
          submitEmailLogin({
            email: userEmail,
            requestSource: window.location.href,
            sourceOrg: searchParams.get('org')
          });
        }
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [userEmail, searchParams, submitEmailLogin, navigate]);

  async function continueAsOldUser() {
    const email = localStorage.getItem("last_user");
    if (email) {
      submitEmailLogin({ email: email.toLocaleLowerCase(), requestSource: window.location.href, sourceOrg: searchParams.get("org") })
    }
  }
  async function handleLogin() {
    if (regExEmail.test(email)) {
      sessionStorage.setItem("last_user", email.toLowerCase());
      submitEmailLogin({ email: email.toLocaleLowerCase(), requestSource: window.location.href, sourceOrg: searchParams.get("org") })
      return
    }
    if (regExPhone.test(email)) {
      const parsed = parsePhoneNumber(email)
      if (parsed) {
        const { countryCallingCode, nationalNumber } = parsed
        submitPhoneLogin({
          requestSource: window.location.href,
          sourceOrg: searchParams.get("org"),
          countryCode: countryCallingCode, phone: nationalNumber
        })
      }
      return
    }
    alert('Invalid email.')
  }

  return (
    <div className="flex flex-col px-16 py-10 h-screen bg-hero bg-cover">
      <DarkLogo />
      <form className='flex flex-col space-y-4 max-w-md my-12 p-4 bg-white rounded-lg'>
        <p className='font-bold font-inter text-4xl/normal mb-[10px]'>Your digital identity is our top priority<span className='text-red-500'>.</span></p>
        {showContinue ?
          <div className='flex flex-col'>
            <p className='font-bold font-inter text-3xl/normal'>Hello, Welcom back<span className='text-red-500'>.</span></p>
            <p style={{ padding: '10px 0' }} className='font-inter text-1xl/normal'>Sign in as <span className='font-bold'>{sessionEmail}</span></p>
            <ContainedButton
              style={{ padding: '10px 0' }}
              loading={loadingEmail || loadingPhone}
              onClick={continueAsOldUser}
              title='Continue' />
            <Button
              style={{ paddingTop: '20px' }}
              color='secondary'
              onClick={() => setShowContinue(false)}>
              Switch to different account
            </Button>
          </div>
          :
          <div className='flex flex-col'>
            <TextField
              sx={{
                paddingBottom: 5
              }}
              fullWidth
              id="emailInput"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleLogin()
                  e.preventDefault();
                }
              }}
              value={email}
              onChange={event => setEmail(event.target.value)}
              label='Enter your email' />
            <ContainedButton
              disabled={!email}
              loading={loadingEmail || loadingPhone}
              onClick={handleLogin}
              title='Login' />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '16px' }}>
              <Button
                onClick={handleRegister}>
                <p style={{ color: '#5F6971' }}>New user? <span style={{ color: '#FB3B8C' }} className='font-bold'>sign up</span></p>
              </Button>
              <Button
                sx={{ color: '#5F6971' }}
                onClick={handleRestSecret}>
                Can't login?
              </Button>
            </Box>
          </div>
        }
      </form>
      {open && <AuthPage ttr={ttr} sessionId={sessionId} onClose={() => setOpen(false)} open={open} email={email} />}
      <div className='flex-1' />
    </div>
  )
}
