import { ChangeEvent, useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowsProp,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { IoMdMore, IoMdClose } from "react-icons/io";
import './GroupsTab.modules.css'
import { useParams } from "react-router-dom";
import { Box, Button, Link, Modal, TextField, Typography, Pagination, PaginationItem, InputLabel, RadioGroup, FormControlLabel, Radio, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { useForm } from "react-hook-form";
import { validateRequiredField } from "src/share/utils";
import { useMutation, useQuery } from "react-query";
import { DeleteGroupFn, createGroupFn, getOrgGroupsFn, updateGroupFn } from "src/api";
import { queryClient } from "src/query-client";
import { CiSearch } from "react-icons/ci";
import { Images } from "src/assets";
import CustomPagination from "src/dashboard/components/DataTable/CustomPagination";

const POLICIES = [1, 2, 3, 4, 5]

interface GroupTabProps {
  handleGroupClick: (groupId: number) => void;
}

export function GroupsTab({ handleGroupClick }: GroupTabProps) {
  const [search, setSearch] = useState("");

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [filteredRows, setFilteredRows] = useState<GridRowsProp>([]);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const { orgId } = useParams();
  const [groupModal, setGroupModal] = useState<{
    isOpen: boolean;
    headerText: string;
    title: string;
    subtitle: string;
    group?: EditGroupInput;
  }>({
    isOpen: false,
    headerText: 'New Group',
    title: 'Creating Group',
    subtitle: 'Creating group makes user management more efficient and easy'
  });
  const { isFetching, data: groups } = useQuery({
    queryKey: ["orgGroups", orgId],
    queryFn: () => {
      if (!orgId) return;
      return getOrgGroupsFn({ orgId });
    },
    keepPreviousData: true,
  });
  const { mutate: createGroup, isLoading: isCreatingGroup } = useMutation(
    createGroupFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["orgGroups"] });
        closeGroupModal();
        handleClick()
      },
    }
  );
  const { mutate: updateGroup, isLoading: isUpdatingGroup } = useMutation(
    updateGroupFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["orgGroups"] });
        closeGroupModal();
      },
    }
  );

  const { mutate: deleteOrganizationGroup, isLoading: isDeletingGroup } = useMutation(
    DeleteGroupFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["orgGroups"] });
        alert("The group was succefully deleted");
      },
    }
  );

  useEffect(() => {
    if (!groups) return;
    setRows(groups);
    // Apply initial filtering when groups change
    applyFilter(groups);
  }, [groups]);

  const applyFilter = (data: GridRowsProp) => {
    if (!search) {
      setFilteredRows(data);
      return;
    }
    const filteredData = data.filter((row) =>
      row.groupName.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredRows(filteredData);
  };

  useEffect(() => {
    applyFilter(rows);
  }, [search, rows]);

  const handleCreateClick = () => {
    setGroupModal({
      isOpen: true,
      headerText: "New Group",
      title: 'Creating Group',
      subtitle: 'Creating group makes user management more efficient and easy'
    });
  };

  const handleCreateGroup = async (group: CreateGroupInput) => {
    if (!orgId) return;
    // createGroup({
    //   orgId,
    //   groupName: group.groupName,
    //   policies: {
    //     f_sequence_length_allowed: group.f_sequence_length_allowed,
    //     f_symbol_location_allowed: group.f_symbol_location_allowed,
    //     f_combo_allowed: group.f_combo_allowed,
    //   },
    // });
    createGroup({
      orgId,
      groupName: group.groupName,
      policies: {
        f_sequence_length_allowed: 2,
        f_symbol_location_allowed: 2,
        f_combo_allowed: 2,
      },
    });
  };

  const handleUpdateGroup = async (group: EditGroupInput) => {
    // updateGroup({
    //   groupId: group.groupId,
    //   groupName: group.groupName,
    //   f_sequence_length_allowed: group.f_sequence_length_allowed,
    //   f_symbol_location_allowed: group.f_symbol_location_allowed,
    //   f_combo_allowed: group.f_combo_allowed,
    // });
    updateGroup({
      groupId: group.groupId,
      groupName: group.groupName,
      f_sequence_length_allowed: 2,
      f_symbol_location_allowed: 2,
      f_combo_allowed: 2,
    });
  };

  const handleCancel = () => {
    closeGroupModal();
  };

  const closeGroupModal = () => {
    setGroupModal((prevGroupModal) => ({ ...prevGroupModal, isOpen: false }));
  };

  const handleEditClick = (id: GridRowId) => {
    const row = rows.find((row) => row.groupId === id);
    if (row === undefined) return;
    setGroupModal({
      isOpen: true,
      headerText: "Edit Group",
      // group: {
      //   groupId: row.groupId,
      //   groupName: row.groupName,
      //   f_sequence_length_allowed: row.policies.f_sequence_length_allowed,
      //   f_symbol_location_allowed: row.policies.f_symbol_location_allowed,
      //   f_combo_allowed: row.policies.f_combo_allowed,
      // },
            group: {
        groupId: row.groupId,
        groupName: row.groupName,
        f_sequence_length_allowed: 2,
        f_symbol_location_allowed: 2,
        f_combo_allowed: 2,
      },
      title: '',
      subtitle: ''
    });
  };

  const handleDeleteClick = async (groupId: number) => {
    if (!orgId) return;

    deleteOrganizationGroup({
      orgId,
      groupId,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "groupId",
      headerName: "Id",
      width: 190,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "groupName",
      width: 460,
      headerAlign: "left",
      headerName: "Name",
      align: 'left',
      headerClassName: "column-style",
      cellClassName: 'row-style',
      renderCell: (params) => {
        return (
          <Link style={{ textDecoration: 'none', color: "#111111" }}
            onClick={(e) => {
              e.preventDefault();
              handleGroupClick(params.row.groupId);
            }}
          >
            {params.row.groupName}
          </Link>
        );
      },
    },
    // {
    //   field: "f_sequence_length_allowed",
    //   headerName: "Fail Sequence length allowed",
    //   width: 300,
    //   headerClassName: "column-style",
    //   align: 'left',
    //   headerAlign: "left",
    //   cellClassName: 'row-style',
    //   type: "number",
    //   valueGetter: (params) => {
    //     return params.row.policies.f_sequence_length_allowed;
    //   },
    //   valueSetter: (params) => {
    //     params.row.policies.f_sequence_length_allowed = params.value;
    //     return params.row;
    //   },
    // },
    // {
    //   field: "f_symbol_location_allowed",
    //   headerName: "Fail Symbol location allowed",
    //   width: 300,
    //   headerClassName: "column-style",
    //   cellClassName: 'row-style',
    //   align: 'left',
    //   headerAlign: "left",
    //   type: "number",
    //   valueGetter: (params) => {
    //     return params.row.policies.f_symbol_location_allowed;
    //   },
    //   valueSetter: (params) => {
    //     params.row.policies.f_symbol_location_allowed = params.value;
    //     return params.row;
    //   },
    // },
    // {
    //   field: "f_combo_allowed",
    //   headerName: "Fail Combo allowed",
    //   width: 240,
    //   align: 'left',
    //   headerAlign: "left",
    //   headerClassName: "column-style",
    //   cellClassName: 'row-style',
    //   type: "number",
    //   valueGetter: (params) => {
    //     return params.row.policies.f_combo_allowed;
    //   },
    //   valueSetter: (params) => {
    //     params.row.policies.f_combo_allowed = params.value;
    //     return params.row;
    //   },
    // },
    {
      field: "usersCount",
      width: 350,
      align: 'left',
      headerAlign: "left",
      headerName: "Users Count",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      type: "number",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      align: 'center',
      headerAlign: "center",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      type: "actions",
      renderCell: (params) => (
        <ActionsCell row={params.row as EditGroupInput} />
      ),
    },
  ];


  const ActionsCell: React.FC<{ row: EditGroupInput }> = ({ row }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };


    return (
      <>
        <IconButton aria-controls="more-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <IoMdMore size={32} />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >

          <MenuItem sx={{ padding: '14px' }} onClick={() => handleEditClick(row.groupId)}>
            <img style={{ marginRight: '12px' }} src={Images.icEdit} width="20" height="20" />
            Edit group
          </MenuItem>
          <MenuItem sx={{ padding: '14px' }} onClick={() => handleDeleteClick(row.groupId)}>
            <img style={{ marginRight: '12px' }} src={Images.icTrash} width="20" height="20" />
            Delete group
          </MenuItem>
        </Menu>
      </>
    );
  };


  return (
    <>
      <Box sx={{
        height: 600,
        width: '100%',
        '& .column-style': {
          backgroundColor: '#F3F2F7',
          fontWeight: "bold"
        },
        '& .row-style': {
          color: '#111111',
          fontSize: '14px'
        },
      }}>
        <Box className="header-group">
          <div className="product-search">
            <CiSearch size={20} />
            <input
              type="text"
              placeholder="Search group"
              className="products__search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <GroupsToolbar setRows={setRows} handleCreateClick={handleCreateClick} />
        </Box>
        <DataGrid
          loading={isFetching}
          columns={columns}
          rows={filteredRows}
          getRowId={(row) => row.groupId}
          editMode="row"
          slots={{
            pagination: CustomPagination
          }}
          disableColumnMenu
          disableColumnFilter
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          sx={{
            fontSize: '14px',
            color: '#111111',
            mt: 2,
            border: '1px solid #DCDBE8',
            borderRadius: '8px',
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "break-spaces",
              fontWeight: "500",
              fontFamily: "Inter",
              lineHeight: 1.2,
            },
            "& .MuiDataGrid-columnHeader": {
              height: "unset",
            },
            "& .MuiDataGrid-columnHeaders": {
              maxHeight: "200px",
            },
            '& .MuiPaginationItem-root.Mui-selected:hover': {
              backgroundColor: '#303030',
              color: 'white',
            },
          }}
        />
      </Box>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={handleClose}
        sx={{
          '.MuiSnackbarContent-root': {
            background: '#303030 !important',
            borderRadius: '30px'
          }
        }}
        message="A new group was created successfuly"
      />
      <GroupFormModal
        handleCreateGroup={handleCreateGroup}
        handleUpdateGroup={handleUpdateGroup}
        handleCancel={handleCancel}
        isOpen={groupModal.isOpen}
        headerText={groupModal.headerText}
        title={groupModal.title}
        subtitle={groupModal.subtitle}
        group={groupModal.group}
        isSubmitting={isCreatingGroup || isUpdatingGroup || isDeletingGroup}
      />
    </>
  );
}

interface GroupsToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  handleCreateClick: () => void;
}

function GroupsToolbar({ setRows, handleCreateClick }: GroupsToolbarProps) {
  return (
    <Box sx={{ marginBottom: 1 }}>
      <Button
        sx={{
          "&:hover": { backgroundColor: "#111111" },
          backgroundColor: "#111111"
        }}
        className="button-group" variant="contained" onClick={handleCreateClick}>
        New Group
      </Button>
    </Box >
  );
}

interface GroupFormModalProps {
  isOpen: boolean;
  handleCreateGroup: (group: CreateGroupInput) => Promise<void> | void;
  handleUpdateGroup: (group: EditGroupInput) => Promise<void> | void;
  headerText: string;
  title: string;
  subtitle: string;
  handleCancel: () => void;
  group?: EditGroupInput;
  isSubmitting: boolean;
}

function GroupFormModal({
  isOpen,
  handleCreateGroup,
  handleUpdateGroup,
  headerText,
  title,
  subtitle,
  group,
  handleCancel,
  isSubmitting,
}: GroupFormModalProps) {
  const {
    register,
    formState: { errors, isValid, dirtyFields },
    handleSubmit: formHandleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm<CreateGroupInput | EditGroupInput>({ defaultValues: group });
  const [isEditMode, setIsEditMode] = useState(false);
  // const [groupFields, setGroupFields] = useState<CreateGroupInput>({
  //   groupName: "",
  //   f_sequence_length_allowed: null,
  //   f_symbol_location_allowed: null,
  //   f_combo_allowed: null,
  // });
  const [groupFields, setGroupFields] = useState<CreateGroupInput>({
    groupName: "",
    f_sequence_length_allowed: 2,
    f_symbol_location_allowed: 2,
    f_combo_allowed: 2,
  });
  // const [initialGroupFields, setInitialGroupFields] = useState<CreateGroupInput>({
  //   groupName: "",
  //   f_sequence_length_allowed: null,
  //   f_symbol_location_allowed: null,
  //   f_combo_allowed: null,
  // });
  const [initialGroupFields, setInitialGroupFields] = useState<CreateGroupInput>({
    groupName: "",
    f_sequence_length_allowed: 2,
    f_symbol_location_allowed: 2,
    f_combo_allowed: 2,
  });

  // useEffect(() => {
  //   if (group && group.groupId) {
  //     setIsEditMode(true);
  //     setGroupFields({
  //       groupName: group.groupName || "",
  //       f_sequence_length_allowed: group.f_sequence_length_allowed ?? null,
  //       f_symbol_location_allowed: group.f_symbol_location_allowed ?? null,
  //       f_combo_allowed: group.f_combo_allowed ?? null,
  //     });
  //     setInitialGroupFields({
  //       groupName: group.groupName || "",
  //       f_sequence_length_allowed: group.f_sequence_length_allowed ?? null,
  //       f_symbol_location_allowed: group.f_symbol_location_allowed ?? null,
  //       f_combo_allowed: group.f_combo_allowed ?? null,
  //     });
  //   } else {
  //     setIsEditMode(false);
  //     setGroupFields({
  //       groupName: "",
  //       f_sequence_length_allowed: null,
  //       f_symbol_location_allowed: null,
  //       f_combo_allowed: null,
  //     });
  //     setInitialGroupFields({
  //       groupName: "",
  //       f_sequence_length_allowed: null,
  //       f_symbol_location_allowed: null,
  //       f_combo_allowed: null,
  //     });
  //   }
  // }, [group, isOpen]);
  useEffect(() => {
    if (group && group.groupId) {
      setIsEditMode(true);
      setGroupFields({
        groupName: group.groupName || "",
        f_sequence_length_allowed: group.f_sequence_length_allowed ?? 2,
        f_symbol_location_allowed: group.f_symbol_location_allowed ?? 2,
        f_combo_allowed: group.f_combo_allowed ?? 2,
      });
      setInitialGroupFields({
        groupName: group.groupName || "",
        f_sequence_length_allowed: group.f_sequence_length_allowed ?? 2,
        f_symbol_location_allowed: group.f_symbol_location_allowed ?? 2,
        f_combo_allowed: group.f_combo_allowed ?? 2,
      });
    } else {
      setIsEditMode(false);
      setGroupFields({
        groupName: "",
        f_sequence_length_allowed: 2,
        f_symbol_location_allowed: 2,
        f_combo_allowed: 2,
      });
      setInitialGroupFields({
        groupName: "",
        f_sequence_length_allowed: 2,
        f_symbol_location_allowed: 2,
        f_combo_allowed: 2,
      });
    }
  }, [group, isOpen]);

  const sendSubmit = async () => {

    if (!isValid) {
      return;
    }
    const isNew = group === undefined;
    const newGroup = getValues();

    if (isNew) {
      await handleCreateGroup(newGroup);
      // setGroupFields({
      //   groupName: "",
      //   f_sequence_length_allowed: null,
      //   f_symbol_location_allowed: null,
      //   f_combo_allowed: null,
      // });
      setGroupFields({
        groupName: "",
        f_sequence_length_allowed: 2,
        f_symbol_location_allowed: 2,
        f_combo_allowed: 2,
      });
      return;
    }
    await handleUpdateGroup({ ...groupFields, groupId: group.groupId });
  };

  const isEmpty = !groupFields.f_sequence_length_allowed
  || !groupFields.f_symbol_location_allowed
  || !groupFields.f_combo_allowed
  || !groupFields.groupName;

  const isDirty =
  groupFields.groupName !== initialGroupFields.groupName ||
  groupFields.f_sequence_length_allowed !==
    initialGroupFields.f_sequence_length_allowed ||
  groupFields.f_symbol_location_allowed !==
    initialGroupFields.f_symbol_location_allowed ||
  groupFields.f_combo_allowed !== initialGroupFields.f_combo_allowed;

  const isSubmitDisabled = isSubmitting || (!isEditMode && isEmpty) || (isEditMode && !isDirty);

  const isGroupNameEmpty = groupFields.groupName.trim() === ''

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            {headerText}
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        {group && <Box className='title-modal'>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <Typography color='#666666'>
            {subtitle}
          </Typography>
        </Box>}
        <form style={{ padding: '60px', width: '100%' }} onSubmit={formHandleSubmit(sendSubmit)}>
          {group && (
            <Typography variant="body1">Group ID: {group.groupId}</Typography>
          )}
          <TextField
            {...register("groupName", {
              required: true,
              validate: validateRequiredField,
            })}
            helperText={errors.groupName && errors.groupName.message}
            error={!!errors.groupName}
            label="Group Name"
            fullWidth
            margin="normal"
            value={groupFields.groupName}
            onChange={(e) => setGroupFields(
              (prevState) => ({ ...prevState, groupName: e.target.value }
            ))}
          />
          {/* <TextField
            {...register("f_sequence_length_allowed", {
              required: false,
              setValueAs: (value) => {
                console.log('f_sequence_length_allowed', value)

                return value === null ? "" : value;
              },
            })}
            value={groupFields.f_sequence_length_allowed ?? ""}
            onChange={(e) => setGroupFields(
              (prevState) => ({ ...prevState, f_sequence_length_allowed: e.target.value === ""
              ? null
              : parseInt(e.target.value)
            }))}
            select
            type="number"
            helperText={
              errors.f_sequence_length_allowed &&
              errors.f_sequence_length_allowed.message
            }
            error={!!errors.f_sequence_length_allowed}
            label="Fail Sequence Length Allowed"
            fullWidth
            margin="normal"
          >
            {POLICIES.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            {...register("f_symbol_location_allowed", {
              required: false,
              setValueAs: (value) => {
                console.log('f_symbol_location_allowed', value)

                return value === null ? "" : value;
              },
            })}
            value={groupFields.f_symbol_location_allowed ?? ""}
            onChange={(e) => setGroupFields(
              (prevState) => ({ ...prevState, f_symbol_location_allowed: e.target.value === ""
               ? null
               : parseInt(e.target.value)
            }))}
            select
            type="number"
            helperText={
              errors.f_symbol_location_allowed &&
              errors.f_symbol_location_allowed.message
            }
            error={!!errors.f_symbol_location_allowed}
            label="Fail Symbol Location Allowed"
            fullWidth
            margin="normal"
          >
            {POLICIES.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            {...register("f_combo_allowed", {
              required: false,
              setValueAs: (value) => {
                console.log();
                return value === null ? "" : value;
              },
            })}
            select
            helperText={
              errors.f_combo_allowed && errors.f_combo_allowed.message
            }
            error={!!errors.f_combo_allowed}
            value={groupFields.f_combo_allowed ?? ""}
            onChange={(e) => setGroupFields(
              (prevState) => ({ ...prevState, f_combo_allowed: e.target.value === ""
              ? null
              : parseInt(e.target.value)
            }))}

            label="Fail Combo Allowed"
            fullWidth
            margin="normal"
          >
            {POLICIES.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField> */}
          <Box sx={{ left: 0, padding: '0 60px', width: '100%', bottom: 12, position: 'absolute', justifyContent: 'space-between', display: 'flex' }}>
            <Button
              variant="text"
              onClick={handleCancel}
              sx={{ color: '#303030' }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: '97px !important',
                "&:hover": { backgroundColor: "#111111" },
                backgroundColor: "#111111",
              }}
              className="button-group"
              variant="contained"
              type="submit"
              disabled={isSubmitDisabled || isGroupNameEmpty}
            >
              {group ? 'Update' : 'Create'}
            </Button>
          </Box>
        </form>
      </Box >
    </Modal >
  );
}

type CreateGroupInput = {
  groupName: string;
  f_sequence_length_allowed: number | null;
  f_symbol_location_allowed: number | null;
  f_combo_allowed: number | null;
};

type EditGroupInput = CreateGroupInput & {
  groupId: number;
};

export default GroupsTab;
