import { Box, Button } from '@mui/material';
import { useState } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from 'src/share/utils/cropImg';
interface DemoProps {
  img: string | null | undefined;
  onSave: (croppedImageBlob: Blob) => void;
  handleCloseModal: () => void;
}

export const Demo: React.FC<DemoProps> = ({ img, onSave, handleCloseModal }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{ width: number; height: number; x: number; y: number } | null>(null);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }
  const handleSave = async () => {
    if (!croppedAreaPixels || !img) return;
    const croppedImageBlob = await getCroppedImg(img, croppedAreaPixels);
    onSave(croppedImageBlob);
    handleCloseModal();
  };

  if (!img) {
    return <></>
  }

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: 180,
          background: "#f5f5f5"
        }}
      >
        <Cropper
          image={img}
          crop={crop}
          zoom={zoom}
          showGrid={false}
          cropShape={'round'}
          style={{containerStyle: { borderRadius: 12},}}
          aspect={1}
          cropSize={{ width: 180, height: 180 }}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <Box sx={{ marginTop: '20px', alignItems: 'center', display: 'flex', justifyContent: 'center', gap: '40px' }}>
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            cursor: "pointer",
            border: "none",
            outline: "none",
            padding: "5px 5px",
            borderRadius: "8px",
            fontSize: "1rem",
            fontWeight: 500,
            transition: "background-color 0.3s, color 0.3s",
            backgroundColor: "#FB3B8C",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#d32f6d",
            },
          }}
        >
          Save
        </Button>
        <Button
          onClick={handleCloseModal}
          variant="outlined"
          sx={{
            cursor: "pointer",
            borderColor: "#111111",
            outline: "none",
            padding: "5px 5px",
            borderRadius: "8px",
            fontSize: "1rem",
            fontWeight: 500,
            transition: "background-color 0.3s, color 0.3s",
            color: "#111111",
            "&:hover": {
              borderColor: "#111111",
            },
          }}
        >
          Cancel
        </Button>
      </Box>
    </>
  )
}
